/**
 * Storage封装
 */
const STORAGE_KEY = 'hcontrol_download';

function _getStorage(type) {
  let storage = type === 'session' ? 'sessionStorage' : 'localStorage';
  return JSON.parse(window[storage].getItem(STORAGE_KEY) || '{}');
};

function _clear(key, type) {
  let res = _getStorage(type);
  delete res[key];
  return res;
};

export function setLocalStorage(key, val) {
  let res = _getStorage('local');
  res[key] = val;
  window.localStorage.setItem(STORAGE_KEY, JSON.stringify(res));
};

export function getLocalStorage(key) {
  let res = _getStorage('local');
  return res[key];
};

export function setSessionStorage(key, val) {
  let res = _getStorage('session');
  res[key] = val;
  window.sessionStorage.setItem(STORAGE_KEY, JSON.stringify(res));
};

export function getSessionStorage(key) {
  let res = _getStorage('session');
  return res[key];
};

export function clearLocalStorage(key) {
  let res = _clear(key, 'local');
  window.localStorage.setItem(STORAGE_KEY, JSON.stringify(res));
};

export function clearSessionStorage(key) {
  let res = _clear(key, 'session');
  window.sessionStorage.setItem(STORAGE_KEY, JSON.stringify(res));
};