import {
  setLocalStorage,
} from '../assets/js/storage.js';

const mutations = {
  //设置用户信息
  setUserInfo(state, userInfo = {}) {
    setLocalStorage('userInfo', userInfo);
    state.userInfo = userInfo;
  },
  //设置语言
  setLang(state, lang) {
    setLocalStorage('lang', lang);
    state.lang = lang;
  },
  //设置登录dialog显示与隐藏
  setShowLogin(state, show) {
    state.showLogin = show;
  },
  //设置注册dialog显示与隐藏
  setShowRegister(state, show) {
    state.showRegister = show;
  },
  //设置注册身份 1-工程师 2-用户
  setRegisterIdentity(state, identity) {
    state.registerIdentity = identity;
  },
  //通过监听addUserSuccess变化来判断添加成功 进行刷新列表等操作
  setAddUserSuccess(state) {
    state.addUserSuccess = state.addUserSuccess + 1;
  },
  //设置修改密码dialog显示与隐藏
  setShowUpdatePwd(state, show) {
    state.showUpdatePwd = show;
  },
  //设置重置密码dialog显示与隐藏
  setShowResetPwd(state, show) {
    state.showResetPwd = show;
  },
  //设置驱动 驱动包 UI模板接口请求参数
  setShareParams(state, data) {
    let key = data.key;
    let val = data.val;
    state.shareParams[key] = val;
  },
  //设置驱动 驱动包 UI模板 列表
  setShareList(state, data) {
    let key = data.key;
    let val = data.val;
    state.shareList[key] = val;
  },
  //设置我的资源请求参数
  setMineParams(state, params) {
    state.mineParams = params;
  },
  //设置我的资源列表
  setMineList(state, data) {
    let key = data.key;
    let val = data.val;
    state.mineList[key] = val;
  },
  //设置资源下载展示的数据
  setResourceList(state, list) {
    state.resourceList = list;
  },
  //设置文档
  setApiDoc(state, apiDoc = null) {
    state.apiDoc = apiDoc;
  },
  //设置授权信息
  setAuthorizationInfo(state, info = {}) {
    setLocalStorage('authorizationInfo', info);
    state.authorizationInfo = info;
  },
};

export default mutations;