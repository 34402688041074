import {
  getLocalStorage
} from '../assets/js/storage.js';

const getters = {
  getUserInfo(state) {
    state.userInfo = getLocalStorage('userInfo') || {};
    return state.userInfo;
  },
  getLang(state) {
    state.lang = getLocalStorage('lang') || 'en';
    return state.lang;
  },
  getAuthorizationInfo(state) {
    state.authorizationInfo = getLocalStorage('authorizationInfo') || {};
    return state.authorizationInfo;
  }
};

export default getters;