import {
  createRouter,
  createWebHashHistory
} from 'vue-router';

const routes = [{
  path: '/',
  name: 'Home',
  component: () => import('../views/Home.vue'),
  redirect: '/index',
  children: [{
      path: '/index',
      name: 'Index',
      component: () => import('../views/Download/Upload.vue')
    },
    {
      path: '/history',
      name: 'History',
      component: () => import('../views/Download/History.vue'),
    },
    {
      path: '/firmware',
      name: 'Firmware',
      component: () => import('../views/Download/Firmware.vue'),
    },
    {
      path: '/remote',
      name: 'Remote',
      component: () => import('../views/Remote/Remote.vue'),
    },
    {
      path: '/account',
      name: 'Account',
      component: () => import('../views/Account/Account.vue'),
    },
    {
      path: '/resource',
      name: 'Resource',
      component: () => import('../views/Resource/Resource.vue'),
    },
    {
      path: '/authorization',
      name: 'Authorization',
      component: () => import('../views/Authorization/Authorization.vue'),
    },
    {
      path: '/support',
      name: 'Support',
      component: () => import('../views/Support/Support.vue'),
    }
  ]
}]

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;