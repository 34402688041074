export default {
  message: {
    download: 'Download',
    softwareDownload: 'Download',
    deviceManagement: 'Device',
    accountManagement: 'Account',
    siginIn: 'Sign In',
    signOut: 'Sign out',
    register: 'Register',
    chinese: 'Chinese',
    english: 'English',
    changePwd: 'Change Password',
    changeYourPwd: 'Change your password',
    forgotPwd: 'Forgot your password',
    noAccount: 'Do you have an account',
    goRegister: 'Go register',
    registerIde: 'Register',
    registerApp: 'Customer Account',
    company: 'Company',
    name: 'Name',
    phone: 'Phone',
    email: 'Email',
    forgotPwd: 'Forgot password',
    code: 'Code',
    currentPwd: 'Current password',
    newPwd: 'Password',
    confirm: 'Confirm',
    create: 'Create',
    name2: 'Name',
    createDate: 'Create Date',
    status: 'Status',
    processing: 'Processing',
    done: 'Done',
    operation: 'Operation',
    send: 'Send',
    more: 'More',
    firmware: 'Firmware',
    hcDownload: 'Download',
    hcDescribe: 'Integrated Development Environment (IDE) is a visual programming software for building control systems, adhering to the concept of parametric design, and provides a variety of resource libraries that are continuously updated to help engineers  design and develop control system efficiently.',
    pc: 'PC',
    pcDescribe: 'The control App for Windows.',
    androidDescribe: 'The control App for Android.',
    iosDescribe: 'The control App for IOS.',
    version: 'Version',
    versionDescribe: 'Release note',
    releaseDate: 'Release date',
    more2: 'More',
    noData: 'No data',
    pleaseSelect: 'Please select',
    total: 'Total',
    online: 'Online',
    offline: 'Offline',
    online2: 'Online',
    offline2: 'Offline',
    all: 'All',
    offlineTime: 'Offline time',
    model: 'Model',
    devices: 'Devices',
    model2: 'Model',
    firmwareVersion: 'Firmware version',
    intranetIP: 'Intranet IP',
    internetIP: 'Internet IP',
    access: 'Access',
    other: 'Other',
    percentage: 'Percentage',
    uncheck: 'Processing',
    agree: 'Done',
    disagree: 'Failed',
    unknown: 'unknown',
    noMatch: 'No matching data',
    product: 'Product',
    downloadOld: 'Be careful！ Version 2.0.0 cannot be directly upgraded to<br/> 2.1.2. You need to upgrade to 2.1.1 first. Before upgrading<br/> please reset the system to ensure sufficient disk space',
    Continue: 'Continue',
    Download2_1_1: 'Download 2.1.1',
    noVersion: 'No corresponding version information！',
    UITemplate: 'Template',
    DriverCode: 'Driver Source Code',
    DrivePackage: 'Driver Package',
    MyResources: 'My Resource',
    PublishDate: 'Publish Date',
    Default: 'Default',
    AllType: 'All Type',
    AllStatus: 'All State',
    Reject: 'Returned',
    ToSubmit: 'Draft',
    Checking: 'Processing',
    Deployed: 'Published',
    LookMore: 'More',
    Found: 'Found ',
    Results: ' results',
    NoMore: 'The end.',
    NoTemplate: 'No results found',
    Update: 'Edit',
    Withdraw: 'Withdraw',
    Submit: 'Submit',
    NoImg: 'The thumbnail is blank',
    Delete: 'Delete',
    OperationFailed: 'Operation failed!',
    BaseInfo: "Base Info",
    Interfaces: "Interfaces",
    Features: "Features",
    Feature: "Feature",
    DriverEvent: "Driver Event",
    IRCommands: "IRCommands",
    VersionNote: "Version Note",
    Syntax: "Syntax",
    Parameters: "Parameters",
    Result: "Result",
    Directory: 'Table of Contents',
    Description: 'Description',
    Back: 'Back',
    WithdrawPrompt: 'Are you sure you want to remove it from the marketplace？',
    Confirm: 'Confirm',
    Save: 'Save',
    Cancel: 'Cancel',
    Tips: 'Information',
    OperationSucceeded: 'Operation succeeded!',
    DeletePrompt: 'Are you sure you want to delete it?',
    Name: 'Name',
    PleaseEnterName: 'Name can not be empty!',
    PleaseEnterBrand: 'Brand can not be empty!',
    PleaseEnterDeviceName: 'DeviceName can not be empty!',
    Version: 'Version',
    PleaseEnterVersion: 'Version name can not be empty!',
    Keywords: 'Keywords',
    PleaseEnterKeywords: 'Keywords can not be empty!',
    Author: 'Publisher',
    PleaseEnterAuthor: 'Publisher name can not be empty.!',
    Brand: 'Brand',
    DeviceName: 'Device Name',
    ReleaseScope: 'Access Option',
    IllegalCharacter: 'Characters are not allowed:',
    EnterUptoFiveKeywords: 'Too many keywords，up to 5',
    KeywordsLength20: 'The keywords is too long',
    DriverUpdate: 'Edit Driver',
    PackageUpdate: 'Edit Package',
    UITemplateUpdate: 'Edit Template',
    Open: 'Public',
    OnlyVisible: 'Private',
    ResourceDownload: 'Resource',
    Private: 'Private',
    Close: 'Close',
    Authorization: 'Authorization',
    NewCode: 'New code',
    GenerationDate: 'Create date',
    Generate: 'Generate',
    MAC: 'MAC',
    Deadline: 'Expiration date',
    ExpirationDate: 'Expiration date',
    AuthorizationDays: 'Usable Days',
    AuthorizationCode: 'Authorization code',
    Copy: 'Copy',
    Start: 'Start',
    PersonalCenter: 'Account Center',
    UpdateAuthorization: 'Edit',
    Company: 'Company',
    FullName: 'Name',
    Support: 'Support',
    YourAccount: 'Your account will expire in ',
    ExpireInDays: " days, please go to the <br/>'Account Center' to renew your account.",
    Go: 'Go',
    MaximumInstallations: 'Maximum Installations',
    MaximumInstallationsCount: 'Maximum Installations',
    Note: 'Note',
    PCs: 'PCs',
    AllPCs: '',
    used: 'used',
    CurrentExpirationDate: 'Current authorization expiration date',
    CurrentInstallations: 'Current maximum authorized installations',
    SubmitApplication: 'Submit',
    Processing: 'Processing',
    notActive: 'not active',
    Type: 'Type',
    Documentation: 'Description',
    File: 'File',
    LicenseMode: 'License Mode',
    Limited: 'Limited',
    Perpetual: 'Perpetual',
  },
  rules: {
    account: 'Please input account',
    password: 'Please input password',
    captcha: 'Please input captcha',
    company: 'Please input company',
    name: 'Please input name',
    phone: 'Please input phone',
    phoneFormat: 'Phone format is incorrect',
    email: 'Please input email',
    emailFormat: 'Email format is incorrect',
    emailCode: 'Please input the verification code',
    confirm: 'Please input the password again',
    currentPwd: 'Please input current password',
    newPwd: 'Please input password',
    differentPwd: 'Those passwords didn’t match. Try again',
    pwdLength: 'Password length cannot be less than 6 digits',
    pwdType: 'Incorrect password format',
    ExpirationDate: 'Please select expiration date',
    PCs: 'Please input the number of PCs',
    Note: 'Please input note',
    MAC: 'Please input mac',
    MACFormat: 'Please enter the correct format, for example: 00:0C:29:CA:E2:60'
  },
  submit: {
    siginIn: 'Submit',
    register: 'Submit',
    changePwd: 'Submit',
    forgotPwd: 'Submit',
  },
  success: {
    siginIn: 'Sign In successful！',
    signOut: 'Sign out successful！',
    register: 'The request has been submitted.',
    changePwd: 'Password changed successfully！',
    sendEmail: 'An email with a verification code was just sent to',
    Copied: 'Copied successfully！',
  },
  failed: {
    siginIn: 'Sign In failed！',
    signOut: 'Sign out failed！',
    register: 'Registration failed！',
    getData: 'Failed to get data！',
    changePwd: 'Failed to change password！',
    sendEmail: 'Failed to send verification code email！',
    Copied: 'Copied failed！',
    MaximumIs: 'the maximum value of this field is ',
  },
}