import {
  createI18n
} from "vue-i18n";
import {
  getLocalStorage
} from '@/assets/js/storage.js';

import zh from "./lang/zh";
import en from "./lang/en";

//https://blog.csdn.net/weixin_45044818/article/details/123808617
const i18n = createI18n({
  locale: getLocalStorage('lang') || "en", //默认语言
  silentFallbackWarn: true, // 关闭控制台警告
  fallbackLocale: 'en', //没有中文的话默认英文
  globalInjection: true, //全局注册 $t
  legacy: false, //设置为false才能在js中使用
  warnHtmlMessage: false,
  messages: {
    en,
    zh
  }
});

export default i18n;