import {
  createStore
} from 'vuex';

import mutations from './mutations.js';
import getters from './getters.js';
import actions from './actions.js';


export default createStore({
  state: {
    userInfo: {}, //用户信息
    lang: '', //语言
    showLogin: false, //是否显示登录dialog
    showRegister: false, //是否显示注册dialog
    registerIdentity: 1, //注册身份 1-工程师 2-用户
    addUserSuccess: 1, //监听添加用户成功（每次添加成功 +1）
    showUpdatePwd: false, //是否显示修改密码dialog
    showResetPwd: false, //是否显示重置密码dialog
    //驱动 驱动包 UI模板 参数
    shareParams: {
      template: null,
      driver: null,
      package: null
    },
    //驱动 驱动包 UI模板 缓存数据
    shareList: {
      template: null,
      driver: null,
      package: null
    },
    mineParams: {}, //我的资源参数
    mineList: { //我的资源所有数据
      template: [],
      driver: [],
      package: [],
      all: []
    },
    //记录列表滚动高度极滚动页码-暂时留用
    shareScroll: {
      template: {
        top: 0,
        page: 1,
      },
      driver: {
        top: 0,
        page: 1,
      },
      package: {
        top: 0,
        page: 1,
      }
    },
    allVersion: {}, //所有版本信息
    resourceList: [], //资源下载展示的数据
    resourceCount: 0, //资源下载数据个数
    resourceNav: 'template', //资源下载左侧导航栏
    rowType: 2, //0-驱动 1-驱动包 2-ui模板
    rowId: '', //当前查看文档数据的id
    image: '', //查看大图
    apiDoc: null, //查看文档
    loading: false, //加载中
    logoutPage: ['/remote', '/account', '/resource', '/authorization', '/support'], //点击退出后 需要跳转到首页的页面
    expirationDays: -1, //到期天数  小于1天显示0天
    authorizationInfo: {}, //授权信息
  },
  mutations,
  actions,
  getters
});