//默认排序：按照状态排序-且每个状态的数据创建时间为倒序
function sortDefault(list, type) {
  let reject = [], //已拒绝-审核退回
    toSubmit = [], //待提交
    checking = [], //审核中
    deployed = []; //已发布
  list.forEach(item => {
    if (typeof type === 'number') item.resourcesType = type;
    if (item.shareStatus === 'Reject') {
      reject.push(item);
    };
    if (item.shareStatus === 'ToSubmit') {
      toSubmit.push(item);
    };
    if (item.shareStatus === 'Checking') {
      checking.push(item);
    };
    if (item.shareStatus === 'Deployed') {
      deployed.push(item);
    };
  });
  sortOfTime(reject);
  sortOfTime(toSubmit);
  sortOfTime(checking);
  sortOfTime(deployed);
  return [...reject, ...toSubmit, ...checking, ...deployed];
};

//根据时间排序-倒序
function sortOfTime(list) {
  if (!list.length) return;
  list.sort(function (a, b) {
    return new Date(b.lastUpdateTime) < new Date(a.lastUpdateTime) ? -1 : 1;
  });
};

const actions = {
  setMineList({
    commit
  }, data) {
    let driver = sortDefault(data.driver || [], 0); //驱动
    let packages = sortDefault(data.package || [], 1); //驱动包
    let template = sortDefault(data.ui || [], 2); //UI模板
    let all = sortDefault([...driver, ...packages, ...template]);
    commit('setMineList', {
      key: 'driver',
      val: driver
    });
    commit('setMineList', {
      key: 'package',
      val: packages
    });
    commit('setMineList', {
      key: 'template',
      val: template
    });
    commit('setMineList', {
      key: 'all',
      val: all
    });
  }
};

export default actions;