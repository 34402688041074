import {
  createApp
} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'element-plus/dist/index.css';
import './assets/iconfont/iconfont.css';
import './assets/iconfont/icontype.css';
import i18n from './i18n/index';
import lazyPlugin from 'vue3-lazy';
import ElementPlus from 'element-plus';

const app = createApp(App);
app.use(store)
  .use(router)
  .use(i18n)
  .use(ElementPlus)
  .use(lazyPlugin, {
    loading: require('@/assets/img/loading-spokes.svg'),
    error: require('@/assets/img/img-err.png')
  })
  .mount('#app');
export default app;